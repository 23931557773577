<template>
  <b-modal 
    id="modal-image"
    centered
    hide-header
    hide-footer
    class
    dialog-class="justify-content-center mw-100"
    content-class="modal-image-content"
  >
    <div class="position-relative d-inline-block">
      <b-img :src="src" rounded fluid></b-img>
      <a :href="src" class="open-new-tab" target="_blank" title="Open image in new tab">
        <fawesome-pro variant="far" icon="external-link" />
      </a>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "ModalImage",
  props: ["src"],
};
</script>
<style scoped>
.open-new-tab {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  border-radius: 0 .25rem 0 .25rem;
  background: rgba(76, 76, 76, 0.9);
  color: white;
}
</style>
